export default {
  GET(state, data) {
    state.paymentGateway = { ...data }
  },
  LIST(state, data) {
    state.paymentGateways = data
  },
  SET_FORM(state, data) {
    state.paymentGatewayForm = { ...data }
  },
}
