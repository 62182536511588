import axios from '@/libs/axios'
import crudActions from '@/libs/storeConstActions'
import { stringify } from 'qs'

const endpoint = 'payment-gateways'

const get = ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/${endpoint}/${id}`)
    .then(response => {
      commit('GET', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const getList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  axios.get(`/${endpoint}`, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

const del = (ctx, data) => axios.delete(`/${endpoint}/${data}`)
const update = (ctx, data) => axios.put(`/${endpoint}/${data.id}`, data)
const create = (ctx, data) => axios.post(`/${endpoint}`, data)

// Alias getList to list
// const list = ({ dispatch }, queryParam) => Actions.list({ dispatch }, queryParam)

const Actions = crudActions(endpoint)

export default {
  ...Actions,
  // list,
  get,
  getList,
  del,
  update,
  create,
}
