import axios from '@/libs/axios'

const endpoint = '/settings/integrations'
const setEmailage = (ctx, data) => axios.post(`${endpoint}/emailage`, data)
const getEmailage = () => axios.get(`${endpoint}/emailage`)
const setUsps = (ctx, data) => axios.post(`${endpoint}/usps`, data)
const getUsps = () => axios.get(`${endpoint}/usps`)
const setKeyCloak = (ctx, data) => axios.post(`${endpoint}/keycloak`, data)
const getKeyCloak = () => axios.get(`${endpoint}/keycloak`)
const setTelesign = (ctx, data) => axios.post(`${endpoint}/telesign`, data)
const getTelesign = () => axios.get(`${endpoint}/telesign`)

const setSmtp = (ctx, data) => axios.post(`${endpoint}/smtp`, data)
const getSmtp = () => axios.get(`${endpoint}/smtp`)
const setIDScan = (ctx, data) => axios.post(`${endpoint}/idscan`, data)
const getIDScan = () => axios.get(`${endpoint}/idscan`)
const getCustomers = () => axios.get(`${endpoint}/customers`)
const getProducts = () => axios.get(`${endpoint}/products`)
const getCategories = () => axios.get(`${endpoint}/categories`)
const getLastUpdate = () => axios.get(`${endpoint}/lastupdate`)

export default {
  getEmailage,
  setEmailage,
  getUsps,
  setUsps,
  getKeyCloak,
  setKeyCloak,
  getTelesign,
  setTelesign,
  getSmtp,
  setSmtp,
  getIDScan,
  setIDScan,
  getCustomers,
  getProducts,
  getCategories,
  getLastUpdate,
}
