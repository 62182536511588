<template>
  <router-view />
</template>
<script>
import store from '@/store'
import integrationsModule from '@/store/settings/general/integrations'
import { onUnmounted } from '@vue/composition-api'
import paymentGatewaysModule from '@/store/settings/general/integrations/payment-geteways'

export default {
  name: 'Integrations',
  created() {
    this.$emit('updateMenu', 'settings-general-integrations')
    this.$store.dispatch(`${this.MODULE_NAME_PAYMENT_GATEWAYS}/getList`).then(() => {})
      .catch(error => {
        console.error('Error fetching payment gateways:', error)
      })
  },
  setup() {
    const MODULE_NAME = 'settingsIntegrations'
    const MODULE_NAME_PAYMENT_GATEWAYS = 'payment-gateways'
    if (!store.hasModule(MODULE_NAME_PAYMENT_GATEWAYS)) { store.registerModule(MODULE_NAME_PAYMENT_GATEWAYS, paymentGatewaysModule) }
    if (!store.hasModule(MODULE_NAME)) { store.registerModule(MODULE_NAME, integrationsModule) }

    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) { store.unregisterModule(MODULE_NAME) }
      if (store.hasModule(MODULE_NAME_PAYMENT_GATEWAYS)) { store.unregisterModule(MODULE_NAME_PAYMENT_GATEWAYS) }
    })
    return {
      MODULE_NAME_PAYMENT_GATEWAYS,
    }
  },
}
</script>

<style scoped>

</style>
